<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Shop Name -->
          <validation-provider
            #default="validationContext"
            name="Shop Name"
            rules="required"
          >
            <b-form-group
              label="Shop Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Shop Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Shop Type"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="userForm.merchant_type_id"
              label="name"
              :reduce="merchant => merchant.id"
              :options="merchantType"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="User | You can Search By Name Or Phone Number"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="userName"
              label="name"
              :filterable="false"
              :reduce="merchant => merchant"
              :options="users"
              @search="onSearch"
              @input="selectUser($event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Client Cashback"
              label-for="CC"
            >
              <b-form-input
                id="CC"
                v-model.number="userForm.client_cashback"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Client Cashback"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="ZeroCash Cashback"
              label-for="ZC"
            >
              <b-form-input
                id="ZC"
                v-model.number="userForm.zerocash_cashback"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="ZeroCash Cashback"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Business Type"
              label-for="business_type"
            >
              <b-form-input
                id="business_type"
                v-model.number="userForm.business_type"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Business Type"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col> -->
        <b-col
          class="d-flex"
          md="6"
        >
          <b-form-group
            label="Active"
            label-for="business_type"
            class="mr-2"
          >
            <b-form-checkbox
              v-model="userForm.is_active"
              checked="true"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            label="Contract"
            label-for="business_type"
          >
            <b-form-checkbox
              v-model="userForm.contract"
              checked="true"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Logo"
            label-for="business_type"
          >
            <b-form-file
              accept=".jpg, .png, .gif"
              placeholder="Choose file"
              @change="onChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="border rounded p-1">
            <h4 class="mb-1">
              Logo
            </h4>
            <b-media
              no-body
              vertical-align="center"
            >
              <b-media-aside>
                <b-img
                  :src="preview"
                  height="200"
                  width="200"
                />
              </b-media-aside>
            </b-media>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addMerchant()"
          >
            Add
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import {
  required, regex,
} from '@validations'
// import previewImage from '@/global-compositions/previewImage'
import merchant from '../merchant'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { vSelect },
  // mixins: [previewImage],
  data() {
    return {
      required,
      regex,
      userForm: {},
      merchantType: [],
      users: [],
      id: this.$store.state.generalIds.id,
      loader: false,
      userName: '',
      preview: null,
      image: null,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()

    const { userForm } = merchant()

    return {
      getValidationState,
      userForm,
      formData,
      setFormData,
    }
  },
  mounted() {
    this.getMerchantTypes()
    this.viewMerchant()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.userForm.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    selectUser(value) {
      this.userForm.user_id = this.users.find(owner => owner.id === value.id).id
    },
    closeModal() {
      this.userForm = {
        user_id: '',
        merchant_type_id: '',
        name: '',
        logo: '',
        is_active: '',
        contract: '',
        client_cashback: '',
        zerocash_cashback: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('merchant')
    },
    getMerchantTypes() {
      axios.get('merchant-types').then(res => {
        this.merchantType = res.data.data
      })
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search)
      }
    },
    search(loading, search) {
      axios.get(`get-users?search=${search}`).then(res => {
        if (search.length >= 2) {
          this.users = res.data.data
        }
        loading(false)
      })
    },
    addMerchant() {
      if (this.id) {
        if (this.userForm.is_active === true) {
          this.userForm.is_active = 1
        } else {
          this.userForm.is_active = 0
        }
        if (this.userForm.contract === true) {
          this.userForm.contract = 1
        } else {
          this.userForm.contract = 0
        }
        this.loader = true
        this.setFormData(this.userForm)
        this.formData.append('_method', 'PUT')
        axios.post(`merchants/${this.id}`, this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.merchantTable.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            if (this.userForm.is_active === true) {
              this.userForm.is_active = 1
            } else {
              this.userForm.is_active = 0
            }
            if (this.userForm.contract === true) {
              this.userForm.contract = 1
            } else {
              this.userForm.contract = 0
            }
            this.loader = true
            this.setFormData(this.userForm)
            axios.post('merchants', this.formData).then(res => {
              this.$parent.$parent.$parent.$refs.merchantTable.getAllData()
              if (res.status === 200 || res.status === 201) {
                this.closeModal()
                this.$toasted.show('Created Successfully', {
                  position: 'top-center',
                  duration: 3000,
                })
              }
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
    viewMerchant() {
      axios.get(`merchants/${this.id}`).then(res => {
        this.userForm = res.data.data
        this.preview = res.data?.data.logo
        this.userName = res.data?.data.user_full_name
        // console.log(this.$parent.$parent)
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
