<template>
  <div>
    <general-table
      ref="merchantTable"
      :api-url="APIURL"
      :add-type="addType"
      :columns="columns"
      :type="type"
      guard="merchants"
      :viw-component="viwComponent"
    >
      <template #cell(contract)="data">
        <b-badge
          pill
          :variant="`light-${ data.item.contract ? 'success' : 'danger'}`"
          class="text-capitalize btn"
        >
          {{ data.item.contract }}
        </b-badge>
      </template>
    </general-table>
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
      >
        <view-merchant />
      </b-modal>
    </div>
    <div>
      <b-modal
        id="merchant"
        ref="genmodal"
        hide-footer
        centered
        size="lg"
        no-close-on-backdrop
        title="Add Shop"
      >
        <add-edit-merchant />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditMerchant from './control-merchant/AddEditMerchant.vue'
import ViewMerchant from './control-merchant/ViewMerchant.vue'

export default {
  components: { GeneralTable, AddEditMerchant, ViewMerchant },
  data() {
    return {
      APIURL: 'merchants',
      addType: 'Add Shop',
      viwComponent: 'show-merchant',
      type: 'modal',
      columns: [
        'select',
        { key: 'id', sortable: true },
        { key: 'name', label: 'Shop Name' },
        { key: 'merchant_type_name', label: 'Shop Type' },
        { key: 'contract', label: 'Contract' },
        { key: 'is_active', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
